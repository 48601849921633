<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="10"
        md="6"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title class="white--text">{{$t('vv6Nvgc5B4zS9mJqEM2fl')}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              v-model="valid"
              ref="form"
              validation
              class="mb-5"
            >
              <v-text-field
                v-model.trim="email"
                label="Email"
                name="email"
                inputmode="email"
                prepend-icon="mdi-email-check-outline"
                type="email"
                required
                :rules="emailRules"
              />
              <v-text-field
                v-model.trim="password"
                id="password"
                :label="$t('xF-IyheMDGEGvxpE9OT4u')"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                :counter="6"
                required
                @keypress.enter.prevent="onSubmit"
                :rules="passwordRules"
              />
            </v-form>
            <p>{{$t('Dlb7yX7cm3RCZ0viDpiPD')}} <router-link to="/reg">{{$t('HjBVpBcuqkYvMTheoyH0M')}}</router-link>
            </p>

          </v-card-text>
          <v-card-actions>
            <v-spacer />

            <VueRecaptcha
              :loadRecaptchaScript="true"
              :sitekey="$root.recaptchaSitekey"
              ref="recaptcha"
              @verify="loginUser"
              @expired="onCaptchaExpired"
              size="invisible"
            >
            </VueRecaptcha>

            <v-btn
              color="primary"
              @click="onSubmit"
              :loading="loading"
              :disabled="!valid || loading"
            >{{$t('FfZqqb6Xd-LAVE7spZ8_9')}}</v-btn>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import VueRecaptcha from 'vue-recaptcha'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { VueRecaptcha },
  data () {
    return {
      valid: false,
      email: '',
      password: '',
      emailRules: [
        v => !!v || this.$t('_YtBk2TxZ-e_28JJfhLD4'),
        v => /.+@.+\..+/.test(v) || this.$t('ghAVjRmoFwk6v_bH4Zxfq')
      ],
      passwordRules: [
        v => !!v || this.$t('2sul8fMJO4WqqqMJO0W54'),
        v => (v && v.length >= 6) || this.$t('jvbuvlY2zzC2NKWUD3nAN')
      ]
    }
  },
  computed: {
    ...mapGetters(['loading']),

  },
  methods: {
    ...mapActions(['setLoading']),

    async loginUser (recaptchaToken) {
      const user = {
        email: this.email,
        password: this.password,
        recaptchaToken
      }
      const login = await this.$store.dispatch('loginUser', user)
      if (login) {
        this.$router.push({ name: 'home' })
      }
      this.email = ''
      this.password = ''
    },
    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },
    onSubmit () {
      if (this.$refs.form.validate()) {
        this.setLoading(true)
        this.$refs.recaptcha.reset()
        this.$refs.recaptcha.execute()
      }
    }
  }
}
</script>
